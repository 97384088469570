import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Home from '../../Home/Home';
import Places from '../Places/Places';
import Buses from '../Buses/Buses';
import Hotels from '../Hotels/Hotels';
import SignupUI from '../../HeaderNav/Signup/SignupUI';
import LoginUI from '../../HeaderNav/Login/LoginUI';
import UserPage from '../../Success/UserPage';
import Kyc from '../../KYC/Kyc';
import Interest from '../../Interests/Interest';
import Logout from '../../Success/Logout';
// import Signup from '../../HeaderNav/Signup/Signup';

const HomeRoute = () => {
    
    return (
        <div>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/places" component={Places} />
                <Route path="/buses" component={Buses} />
                <Route path="/hotels" component={Hotels} />
                <Route path="/signup" component={SignupUI} />
                <Route path="/login" component={LoginUI} />
                <Route path="/success" component= {UserPage} />
                <Route path="/kyc" component={Kyc} />
                <Route path="/interest" component={Interest} />
                <Route path="/logout" component={Logout} />

            </Switch>
        </div>
    )
}

export default HomeRoute
