import React from "react";
import CarouselContainer from "../HomeCarousel/CarouselContainer";
import HomeBox from "../HomePost/HomeBox";
import Hotels from "./Hotels/Hotel";
import Signup from "../HeaderNav/Signup/Signup";
import HomePlace from "./Places/HomePlace";
import Vehicles from "./Vehicles/Vehicles";
import './Home.css';
// import HomeBoxCarousel from '../HomePost/HomeBoxCarousel';

const Home = () => {
  return (
    <div>
      <CarouselContainer />
      <HomeBox />
      <div className="col-lg-2 custom_login_btn">
        <Signup />
      </div>
      <HomePlace />
      <Vehicles />
      <Hotels />
    </div>
  );
};

export default Home;
