import React from 'react'

const Buses = () => {
    return (
        <div>
            Buses
        </div>
    )
}

export default Buses
