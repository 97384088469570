import React from "react";
import HomeBoxCarousel from "./HomeBoxCarousel";
// import { NavLink } from "react-router-dom";
// import bus from "/public/images/Box/bus.jpg";
import plane1 from '../../images/Box/Vehicles/plane.jpg';
import plane2 from '../../images/Box/Vehicles/plane2.jpg';
import bus from '../../images/Box/Vehicles/bus2.jpg';
import place1 from '../../images/Box/Places/places1.jpg';
import place2 from '../../images/Box/Places/places2.jpg';
import place3 from '../../images/Box/Places/places3.jpg';
import hotel1 from '../../images/Box/Hotels/hotel2.jpg';
import hotel2 from '../../images/Box/Hotels/hotel2.jpg';
import hotel3 from '../../images/Box/Hotels/hotel3.jpg';

const HomeBox = () => {
  return (
    <div className="container p-0">
      <div className="row">
        <div className="col-lg-4">
          <HomeBoxCarousel
            img1 = {bus}
            img2 = {plane1}
            img3 = {plane2}
          />
        </div>
        <div className="col-lg-4">
        
          <HomeBoxCarousel
            img1 = {place1}
            img2 = {place2}
            img3 = {place3}
          />
        </div>
        <div className="col-lg-4">
          <HomeBoxCarousel
            img1 = {hotel1}
            img2 = {hotel2}
            img3 = {hotel3}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeBox;
