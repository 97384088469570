import React from 'react'
// import { NavLink } from 'react-router-dom';
import './Hotels.css';

const HotelUI = (props) => {
    return (
        <div>
            <div className="container ma_tb">
                <div className="card hotel_card text-center">
                    <div className="overflow">
                        <img src={props.hotelsImg} alt="hotelsImg" className="hotel_img" />
                    </div>
                    <div className="card-body text-dark">
                        <h4 className="card-title">
                            {props.hotelsName}
                        </h4>
                        <p className="card-text text-secondary">
                           <span>{props.hotelsPlace}</span> {props.hotelsPrice}
                        </p>
                        {/* <NavLink to="/buses">Explore More</NavLink> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HotelUI
