import React from 'react'
// import { NavLink } from 'react-router-dom';
import './HomePlace.css';

const HomePlacesUI = (props) => {
    return (
        <div>
            <div className="container ma_tb">
                <div className="card place_card text-center">
                    <div className="overflow">
                        <img src={props.placeImg} alt="placesImg" className="places_img" />
                    </div>
                    <div className="card-body text-dark">
                        <h4 className="card-title">
                            {props.placeName}
                        </h4>
                        <p className="card-text text-secondary">
                            {props.placeLocation}
                        </p>
                        <p className="card-text text-secondary">
                            {props.placeArea}
                        </p>
                        {/* <NavLink to="/places">Explore More</NavLink> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePlacesUI
