import React from "react";
// import Home from "./component/Home/Home";
import './App.css';
import Copyright from "./component/Copyright/Copyright";
import Footer from "./component/Footer/Footer";
import Header from "./component/HeaderNav/Header";
import HomeRoute from "./component/router/HomeRoute/HomeRoute";
function App() {
  return (
    <div className="App container-fluid body_bg p-0">
      <Header/>
      <HomeRoute />
       <hr className="ma_tb" />
      <Footer/>
      <Copyright />
    </div>
  );
}

export default App;