const placeList = [
    {
        id: 1,
        placeName: "Kathmandu",
        placeImg: '/images/Box/Places/ktm.jpg',
        placeLocation: "Kathmandu, the capital and largest city in Nepal, is like no other city in the world. ",
        
    },
    {
        id: 2,
        placeName: "Pokhara",
        placeImg: '/images/Box/Places/places9.jpg',
        placeLocation: "Pokhara is the gateway to the Himalayas and the starting point for treks.",
        
    },
    {
        id: 3,
        placeName: "Annapurna Region",
        placeImg: '/images/Box/Places/annapurna.jpg',
        placeLocation: "The Annapurna Region is one of the most popular trekking regions in Nepal, ",
        
    },
    {
        id: 4,
        placeName: "Chitwan ",
        placeImg: '/images/Box/Places/chitwan.jpg',
        placeLocation: "This is a wonderful place for wildlife viewing and it has a safari-type atmosphere.",
        
    },
    {
        id: 5,
        placeName: "Lumbini",
        placeImg: '/images/Box/Places/places5.jpg',
        placeLocation: "Lumbini is famous for being the birthplace of Siddhartha Gautama, the historic Buddha. ",
        
    },
    {
        id: 6,
        placeName: "Pokhara",
        placeImg: '/images/Box/Places/peace.jpg',
        placeLocation: "Shanti Stupa in Pokhara has also become a tourist attraction. ",
        
    },
    {
        id: 7,
        placeName: "Jumla",
        placeImg: '/images/Box/Places/rara1.jpg',
        placeLocation: "Rara Lake is the biggest fresh water lake in the Nepalese Himalayas.",
        
    },
]

export default placeList;