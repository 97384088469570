import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { useHistory } from "react-router";
import baseUrl from "../Constant/Api";
import swal from "sweetalert";
import "./Kyc.css";

//
// console.log(userId);

const Kyc = () => {
  const userDetails = JSON.parse(localStorage.getItem("data"));
  const userId = userDetails.pk;
  const history = useHistory();
  const [userKyc, setUserKyc] = useState({
    user: userId,
    mobile: "",
    dob: "",
    gender: "",
    father_name: "",
    mother_name: "",
    current_location: "",
    permanent_location: "",
    education: "",
    occupation: "",
    citizenship_number: "",
    citizenship_issue_district: "",
    citizenship_issue_date: "",
  });

  const [images, setImages] = useState([]);
  const [Pimages, setPImages] = useState([]);

  const handleInputs = (e) => {
    e.persist();
    setUserKyc({ ...userKyc, [e.target.name]: e.target.value });
  };

  const handleImage = (e) => {
    setImages({ citizenship_photo: e.target.files[0] });

    // setImages( e.target.files
    // );
    // setPImages({ Pimages: e.target.files[0] });
  };

  const handlePImage = (e) => {
    setPImages({ pp_size: e.target.files[0] });
  };

  const submitProduct = (e) => {
    e.preventDefault();

    const token = localStorage.getItem("key");
    // const token = "9395f42e47895612a5e193b496260b549ced6435";

    const formData = new FormData();
    formData.append("citizenship_photo", images.citizenship_photo);
    formData.append("pp_size", Pimages.pp_size);
    formData.append("user", userKyc.user);
    formData.append("mobile", userKyc.mobile);
    formData.append("dob", userKyc.dob);
    formData.append("gender", userKyc.gender);
    formData.append("father_name", userKyc.father_name);
    formData.append("mother_name", userKyc.mother_name);
    formData.append("current_location", userKyc.current_location);
    formData.append("permanent_location", userKyc.permanent_location);
    formData.append("education", userKyc.education);
    formData.append("occupation", userKyc.occupation);
    formData.append("citizenship_number", userKyc.citizenship_number);
    formData.append(
      "citizenship_issue_district",
      userKyc.citizenship_issue_district
    );
    formData.append("citizenship_issue_date", userKyc.citizenship_issue_date);

    axios
      .post(`http://ghumfir003.pythonanywhere.com/api/kyc/`, formData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          swal("DONE", "kYC has been updated successfully", "success");
          setUserKyc({
            user: userId,
            mobile: "",
            dob: "",
            gender: "",
            father_name: "",
            mother_name: "",
            current_location: "",
            permanent_location: "",
            education: "",
            occupation: "",
            citizenship_number: "",
            citizenship_issue_district: "",
            citizenship_issue_date: "",
          });
          setImages([]);
          setPImages([]);
        } else {
          swal("Oops!!!", "Please fullfill the details properly", "error");
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <div className="kyc_container">
                  <h1 className="ma_b tc ghumfir_kyc_title">
                    Ghumfir KYC
                    <span className="m-3">
                      <i class="fas fa-align-justify"></i>
                    </span>
                  </h1>
                  <form onSubmit={submitProduct} encType="multipart/form-data">
                    <div className="kyc_personal_detail_div">
                      <h1 className="kyc_user_detail tc">Personal Details</h1>

                      <div className="form-group  form_input_div ">
                        <input
                          className="form_input_field form-control"
                          type="text"
                          name="user"
                          placeholder="User ID"
                          value={userKyc.user}
                          onChange={handleInputs}
                          required
                          disabled
                          hidden
                        />
                      </div>
                      <div className="form-group  form_input_div">
                        <input
                          className="form_input_field form-control"
                          type="text"
                          name="mobile"
                          placeholder="Mobile No."
                          value={userKyc.mobile}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                      <div className="form-group  form_input_div">
                        <input
                          className="form_input_field form-control"
                          type="date"
                          name="dob"
                          placeholder="Date of Birth"
                          value={userKyc.dob}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                      <div className="form-group  form_input_div">
                        <input
                          className="form_input_field form-control"
                          type="text"
                          name="gender"
                          placeholder="Gender"
                          value={userKyc.gender}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                      <div className="form-group  form_input_div">
                        <input
                          className="form_input_field form-control"
                          type="text"
                          name="father_name"
                          placeholder="Father's Name"
                          value={userKyc.father_name}
                          onChange={handleInputs}
                          required
                        />
                      </div>

                      <div className="form-group  form_input_div">
                        <input
                          className="form_input_field form-control"
                          type="text"
                          name="mother_name"
                          placeholder="Mother's Name"
                          value={userKyc.mother_name}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                      <div className="form-group  form_input_div">
                        <input
                          className="form_input_field form-control"
                          type="text"
                          name="current_location"
                          placeholder="Current Location"
                          value={userKyc.current_location}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                      <div className="form-group  form_input_div">
                        <input
                          className="form_input_field form-control"
                          type="text"
                          name="permanent_location"
                          placeholder="Permanent Location"
                          value={userKyc.permanent_location}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </div>

                    <div className="user_kyc_profession_div">
                      <h1 className="user_kyc_profession tc">Profession</h1>
                      <div className="form-group  form_input_div">
                        <input
                          className="form_input_field form-control"
                          type="text"
                          name="education"
                          placeholder="Education"
                          value={userKyc.education}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                      <div className="form-group  form_input_div">
                        <input
                          className="form_input_field form-control"
                          type="text"
                          name="occupation"
                          placeholder="Occupation"
                          value={userKyc.occupation}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </div>
                    <div className="user_kyc_document_details_div">
                      <h1 className="user_kyc_document_details tc">
                        Document Details
                      </h1>

                      <div className="form-group  form_input_div">
                        <input
                          className="form_input_field form-control"
                          type="number"
                          name="citizenship_number"
                          placeholder="Citizenship Number"
                          value={userKyc.citizenship_number}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                      <div className="form-group  form_input_div">
                        <input
                          className="form_input_field form-control"
                          type="text"
                          name="citizenship_issue_district"
                          placeholder="Citizenship Issue District"
                          value={userKyc.citizenship_issue_district}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                      <div className="form-group  form_input_div">
                        <input
                          className="form_input_field form-control"
                          type="date"
                          name="citizenship_issue_date"
                          onfocus="(this.type='date')"
                          placeholder="Citizenship Issue Date"
                          value={userKyc.citizenship_issue_date}
                          onChange={handleInputs}
                          required
                        />
                      </div>
                    </div>
                    <div className="user_kyc_document_photos_div">
                      <h1 className="user_kyc_document_photos tc">
                        Document Photos
                      </h1>
                    </div>
                    <div className="form-group  form_input_div">
                      <input
                        className="form_input_field form-control"
                        type="file"
                        onfocus="(this.type='file')"
                        placeholder="Citizenship Issue Date"
                        onChange={handleImage}
                        multiple
                        name="citizenship_photo"
                      />
                    </div>
                    <div className="form-group  form_input_div">
                      <input
                        className="form_input_field form-control"
                        type="file"
                        onChange={handlePImage}
                        multiple
                        name="pp_size"
                      />
                    </div>

                    <div className="custom_form_btn_div">
                      <button
                        className="btn btn-outline-dark custom_kyc_btn"
                        type="submit"
                      >
                        Update KYC <span></span>
                      </button>

                        <NavLink className="custom_kyc_link" to="/success">
                          Back to profile <span></span>
                        </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kyc;

// className="form_input_field form-control"
