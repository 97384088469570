import React from "react";
// import Search from "./Search";
import { NavLink } from "react-router-dom";
import "./Header.css";
import logoBagpack from "../../images/logo.png";
// import Signup from "./Signup/Signup";
import logo from "../../images/ghumfirlogo.png";

const Header = () => {
  return (
    <div>
      <div className="container-fluid p-0 ">
        <div className="container ">
          <div className="row">
            <div className="col-lg-4 header_logo_div p-0 ">
              <h2 className="header_logo">
                <NavLink to="/">
                  {" "}
                  <img src={logo} alt="ghumfir" />{" "}
                </NavLink>
              </h2>
            </div>
            <div className="col-lg-3">{/* <Search /> */}</div>
            {/* <div className="col-lg-2 custom_login_btn p-2">
              <Login />
            </div> */}
            <div className="col-lg-5 ">
              <div className="custom_logo_img_div">
                <img
                  className="custom_logo_img"
                  src={logoBagpack}
                  alt="logo_bagpack"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
