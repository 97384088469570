import React from 'react'
import { NavLink } from 'react-router-dom';

const Signup = () => {
    return (
        <div>
            <NavLink className="btn btn-dark signup_btn" to="/signup">Signup</NavLink>
        </div>
    )
}

export default Signup
