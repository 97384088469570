import React from 'react';
import './Footer.css';
const Footer = () => {
    return (
        <div>
            <div className="container footer_container_fluid ma_t">
                <div className="row">
                    <div className="col-lg-3 ma_t">
                        <h2 className="footer_title">Contact Us</h2>
                        <ul className="p-0">
                            <li>NCIT Balkumari, Lalitpur</li>
                            <li>Email: wezettas@gmail.com</li>
                            <li>Phone: 9808039867</li>
                        </ul>
                    </div>
                    <div className="col-lg-3">
                        <h2 className="footer_title">Our Teams</h2>
                        <ul className="p-0">
                            <li>Subash Pandey</li>
                            <li>Deepson Shrestha</li>
                            <li>Saugat Ghimire</li>
                            <li>Bibesh Paudel</li>
                        </ul>
                    </div>
                    <div className="col-lg-3">
                        <h2 className="footer_title">Our Services</h2>
                        <ul className="p-0">
                            <li>Companion Finder</li>
                            <li>Hotel Booking</li>
                            <li>Ticket Booking</li>
                        </ul>
                    </div>
                    <div className="col-lg-3 p-0">
                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5938.678290362512!2d85.30578945570304!3d27.74323406918156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19f33b2a7b81%3A0xe8c80f50f28d23f9!2sZETTAMINDS!5e0!3m2!1sen!2snp!4v1632724951044!5m2!1sen!2snp" width="300" height="150" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
                    </div>
                </div>
                <div className="row">
                        <div className="col-md-12 tc ">
                            <h4 className=" icon_header text-center">Follow Us on:</h4>
                            <span><i className="social_icon  p-3 fab fa-facebook"></i></span>
                            <span><i className="social_icon  p-3 fab fa-instagram"></i></span>
                            <span><i className="social_icon  p-3 fab fa-twitter"></i></span>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default Footer
