import React from "react";
// import baseUrl from "../Constant/Api";
// import btnValue from "./Match";
import './MatchUI.css';


const MatchUI = (props) => {
  // const userData = JSON.parse(localStorage.getItem("data"));
  // const userId = userData.pk;
  // const token = localStorage.getItem('key');



  // const setInterested = async (event) => {
    
  //   console.log(event.target.id);

  //   const response = await fetch(`${baseUrl}/match/${userId}/` , {
  //     method: "PATCH",
  //     headers: {
  //       'Content-Type' : 'application/json',
  //       'Authorization': `Token ${token}`
  //     },
  //     body: JSON.stringify({

  //     })
  //   });

  //   const responseData = await response.json();
  //   console.log(responseData);
  //   console.log(responseData.match1);
  //   let match1 = responseData.match1;
  //   console.log(`Matched with user ${match1}`);

  // };

  return (
    <div>
      <div className="container ma_tb">
        <div className="card user_card shadow p-1 mb-5  rounded">
          <div className="card_img_div">
            <img src={props.userImg} alt="User" className="user_img" />{props.userVerified? <><i class="fas fa-badge-check user_verified_badge"></i></> : ''}
          </div>
          <div className="user_details_div">
            <p>Name: {props.userName} </p>
            <p>Email: {props.userEmail} </p>
            {/* <p>Verified:{props.userVerified} </p> */}
            {/* <p>ID: {props.userId}</p> */}
          </div>
          <div className="interest_div">
            {/* <div>
              <button
                onClick={setInterested}
                className="btn btn-outline-success"
                id = {btnValue[1].id}
              >
              {btnValue[0].btnName}
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchUI;
