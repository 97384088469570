import React from "react";
// import { NavLink } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import "./HomeCarouselBox.css";

const HomeBoxCarousel = (props) => {
  return (
    <div className="p-0">
      <div className="carousel_box_container ma_tb">
        <Carousel fade>
          <Carousel.Item interval={1000}>
            <div className="carousel_box">
              
                <img
                  className="carousel_box_img d-block w-100"
                  src={props.img1}
                  alt="img1"
                />
            </div>
          </Carousel.Item>
          <Carousel.Item interval={3000}>
            <div className="carousel_box">
                <img
                  className="carousel_box_img d-block w-100"
                  src={props.img2}
                  alt="img2"
                />
            </div>
          </Carousel.Item>
          <Carousel.Item interval={4000}>
            <div className="carousel_box">
                <img
                  className="carousel_box_img d-block w-100"
                  src={props.img3}
                  alt="img3"
                />
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default HomeBoxCarousel;
