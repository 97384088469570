import React, { useState } from "react";
import baseUrl from "../../Constant/Api";
import {Carousel } from "react-bootstrap";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import swal from "sweetalert";
import loginImage from "../../../images/Carousel/welcom.jpg";
import loginImage2 from "../../../images/Carousel/group.jpg";
import loginImage3 from "../../../images/Carousel/group2.jpg";
import loginImage4 from "../../../images/Carousel/group4.jpg";
import loginImage5 from "../../../images/Carousel/welcom2.jpg";
import loginImage6 from "../../../images/Carousel/welcom3.jpg";
import "./LoginUI.css";

const LoginUI = () => {
  const history = useHistory();
  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  const handleInputs = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    setUser({ ...user, [name]: value });
    // setUser({});
  };

  const loginHandler = async (event) => {
    event.preventDefault();

    const { username, password } = user;

    const response = await fetch(`${baseUrl}/auth/login/`, {
      method: "POST",
      body: JSON.stringify({
        username,
        password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    setUser({
      username: "",
      password: "",
    });
    console.log(data);
    const token = data.key;
    if (token) {
      const datas = await fetch(`${baseUrl}/auth/user/`, {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      const responseData = await datas.json();
      console.log("User data", responseData);
      localStorage.setItem("key", token);
      localStorage.setItem("data", JSON.stringify(responseData));
     
      // console.log(responseData.username);
      console.log("Login Successfully");
      swal("Success","Login Successfully","success" );
      
      history.push(`/success`);
    } else {
      console.log("Unable to login");
      swal("Unable to Login","Please enter the valid credential","error" );
    }
  };

  return (
    // <div className="form_container">
    //   <Form>
    //     <Form.Group className="mb-3" controlId="formBasicEmail">
    //       <Form.Label>UserName</Form.Label>
    //       <Form.Control
    //         type="text"
    //         name="username"
    //         placeholder="Enter Username"
    //         onChange={handleInputs}
    //         value={user.username}
    //       />
    //       <Form.Text className="text-muted">
    //         We'll never share your email with anyone else.
    //       </Form.Text>
    //     </Form.Group>

    //     <Form.Group className="mb-3" controlId="formBasicPassword">
    //       <Form.Label>Password</Form.Label>
    //       <Form.Control
    //         type="password"
    //         name="password"
    //         placeholder="Password"
    //         onChange={handleInputs}
    //         value={user.password}
    //       />
    //     </Form.Group>
    //     <Button variant="primary" type="submit" onClick={loginHandler}   >
    //       Login
    //     </Button>
    //   </Form>
    // </div>

    <div>
      <section className="login-block ma_tb">
        <div className="container ma_tb">
          <div className="row">
            <div className="col-md-4 login-sec">
              <h2 className="text-center">Login Now</h2>
              <form className="login-form">
                <div className="form-group">
                  <label for="exampleInputEmail1" className="text-uppercase">
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    onChange={handleInputs}
                    value={user.username}
                    placeholder=""
                  />
                </div>
                <div className="form-group">
                  <label for="exampleInputPassword1" className="text-uppercase">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder=""
                    name="password"
                    onChange={handleInputs}
                    value={user.password}
                  />
                </div>

                <div className="form-check">
                  <button
                    type="submit"
                    className="btn btn-login ma_tb btn-outline-dark float-right"
                    onClick={loginHandler}
                  >
                    Login
                  </button>
                  <p className="new_signup">
                    New To Ghumfir?
                    <NavLink to="/signup" className="btn btn-outline-success">
                      Register
                    </NavLink>
                  </p>
                  <p className="powered_text">Powered By ZettaMinds</p>
                </div>
              </form>
            </div>
            <div className="col-md-8 banner-sec">
            <h1 className="tc welcome_heading"><span><i class="fas fa-home-alt"></i></span>  Welcome to Ghumfir <span><i class="fas fa-praying-hands"></i></span></h1>
              <Carousel fade>
                <Carousel.Item interval={1000}>
                  <div className="login_banner_div">
                    <img
                      className=" login_img login_banner_img "
                      src={loginImage}
                      alt="img1"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <div className="login_banner_div">
                    <img
                      className=" login_img login_banner_img "
                      src={loginImage}
                      alt="img1"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <div className="login_banner_div">
                    <img
                      className=" login_img login_banner_img "
                      src={loginImage2}
                      alt="img1"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <div className="login_banner_div">
                    <img
                      className=" login_img login_banner_img "
                      src={loginImage3}
                      alt="img1"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <div className="login_banner_div">
                    <img
                      className=" login_img login_banner_img "
                      src={loginImage4}
                      alt="img1"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <div className="login_banner_div">
                    <img
                      className=" login_img login_banner_img "
                      src={loginImage5}
                      alt="img1"
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                  <div className="login_banner_div">
                    <img
                      className=" login_img login_banner_img "
                      src={loginImage6}
                      alt="img1"
                    />
                  </div>
                </Carousel.Item>
              </Carousel>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginUI;
// export default token;
