import React, { useState } from "react";
import baseUrl from "../../Constant/Api";
import { NavLink } from "react-router-dom";
import swal from "sweetalert";
import loginImage from "../../../images/Carousel/welcom.jpg";
import loginImage2 from "../../../images/Carousel/group.jpg";
import loginImage3 from "../../../images/Carousel/group2.jpg";
import loginImage4 from "../../../images/Carousel/group4.jpg";
import loginImage5 from "../../../images/Carousel/welcom2.jpg";
import loginImage6 from "../../../images/Carousel/welcom3.jpg";



import "./Signup.css";
const SignupUI = () => {
  const [user, setUser] = useState({
    fullName: "",
    email: "",
    password1: "",
    password2: "",
    first_name: "",
    last_name: "",
  });
  //   const history = useHistory();

  const handleInputs = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    setUser({ ...user, [name]: value });
  };

  const postData = async (event) => {
    event.preventDefault();

    const { username, email, password1, password2, first_name, last_name } =
      user;

    const response = await fetch(
      `${baseUrl}/auth/register/`,
      {
        method: "POST",
        body: JSON.stringify({
          username,
          email,
          password1,
          password2,
          first_name,
          last_name,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    console.log(data);
    setUser({
      username: "",
      email: "",
      password1: "",
      password2: "",
      first_name: "",
      last_name: "",
    });
    if(response.ok) {
      alert("Registered Successfully!!!")
    }
    else{
      alert("Fill the requirements correctly!!");
    }
    // history.push("/");
  };

  return (
    <div>
      <div className="form_wrapper">
        <div className="form_container">
          <div className="title_container">
            <h2>User Registration Form</h2>
          </div>
          <div className="row clearfix">
            <div className="">
              <form>
                <div className="input_field">
                  <span>
                    <i aria-hidden="true" className="fa fa-user"></i>
                  </span>
                  <input
                    type="text"
                    name="username"
                    placeholder="Username"
                    value={user.username}
                    onChange={handleInputs}
                    required
                  />
                </div>
                <div className="input_field">
                  <span>
                    <i aria-hidden="true" className="fa fa-envelope"></i>
                  </span>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={user.email}
                    onChange={handleInputs}
                    required
                  />
                </div>
                <div className="input_field">
                  <span>
                    <i aria-hidden="true" className="fa fa-lock"></i>
                  </span>
                  <input
                    type="password"
                    name="password1"
                    placeholder="Password"
                    value={user.password1}
                    onChange={handleInputs}
                    required
                  />
                </div>
                <div className="input_field">
                  <span>
                    <i aria-hidden="true" className="fa fa-lock"></i>
                  </span>
                  <input
                    type="password"
                    name="password2"
                    placeholder="Re-type Password"
                    value={user.password2}
                    onChange={handleInputs}
                    required
                  />
                </div>
                <div className="row clearfix">
                  <div className="col_half">
                    <div className="input_field">
                      <span>
                        <i aria-hidden="true" className="fa fa-user"></i>
                      </span>
                      <input
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        value={user.first_name}
                        onChange={handleInputs}
                      />
                    </div>
                  </div>
                  <div className="col_half">
                    <div className="input_field">
                      <span>
                        <i aria-hidden="true" className="fa fa-user"></i>
                      </span>
                      <input
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        value={user.last_name}
                        onChange={handleInputs}
                        required
                      />
                    </div>
                  </div>
                </div>
                <input
                  className="button"
                  onClick={postData}
                  type="submit"
                  value="Register"
                />
                <p className="login_section text-right">
                  Already registered <NavLink to="/login" className="btn btn-outline-dark">Login?</NavLink>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupUI;
