import React from "react";
import { Carousel } from "react-bootstrap";
import image2 from "../../images/Carousel/mardi.jpg";
import image3 from "../../images/Carousel/sauraha.jpg";
import image4 from "../../images/Carousel/annapurna.jpg";
import image5 from "../../images/Carousel/image5.jpg";
import "./Carousel.css";

const CarouselContainer = () => {
  return (
    <div>
      <div className="carousel_container container p-0 ">
        <Carousel>
          <Carousel.Item interval={2000}>
            <div className="carousel_img">
              <img className="d-block w-100 " src={image2} alt="First slide" />
            </div>
            <Carousel.Caption className="carousel_items">
              <h1>Places To Visit</h1>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <div className="carousel_img">
              <img className="d-block w-100 " src={image3} alt="First slide" />
            </div>
            <Carousel.Caption className="carousel_items">
              <h1>Places To Visit</h1>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <div className="carousel_img">
              <img className="d-block w-100 " src={image4} alt="First slide" />
            </div>
            <Carousel.Caption className="carousel_items">
              <h1>Places To Visit</h1>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <div className="carousel_img">
              <img className="d-block w-100 " src={image5} alt="First slide" />
            </div>
            <Carousel.Caption className="carousel_items">
              <h1>Places To Visit</h1>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default CarouselContainer;
