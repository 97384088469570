const hotelsList = [
    {
        id: 1,
        hotelName: "Hyatt Regency",
        hotelImg: '/images/Box/Hotels/hyatt.jpg',
        hotelPlace: "Kathmandu", 
        hotelPrice: "NPR 11,872",
    },
    {
        id: 2,
        hotelName: "Temple Tree Resort ",
        hotelImg: '/images/Box/Hotels/temple.jpg',
        hotelPlace: "Pokhara",
        hotelPrice: "NPR 11,409",
    },
    {
        id: 3,
        hotelName: "Fish Tail Lodge",
        hotelImg: '/images/Box/Hotels/fish.jpg',
        hotelPlace: "Lakeside Pokhara",
        hotelPrice: "NPR 10,500",
    },
    {
        id: 4,
        hotelName: "Hotel Tibet",
        hotelImg: '/images/Box/Hotels/caption.jpg',
        hotelPlace: "Kathmandu",
        hotelPrice: "NPR 10,500",
    },
    {
        id: 5,
        hotelName: "KTM Guest House",
        hotelImg: '/images/Box/Hotels/kathmandu.jpg',
        hotelPlace: "Kathmandu",
        hotelPrice: "NPR 5,159",
    },
    {
        id: 6,
        hotelName: "Glacier Hotel & Spa",
        hotelImg: '/images/Box/Hotels/glacier.jpg',
        hotelPlace: "Pokhara",
        hotelPrice: "NPR 6,784",
    },
    {
        id: 7,
        hotelName: "The Dwarika's Hotel",
        hotelImg: '/images/Box/Hotels/dwarika.jpg',
        hotelPlace: "Kathmandu",
        hotelPrice: "NPR 29,823",
    },
]

export default hotelsList;