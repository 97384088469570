import React from "react";
import HomePlacesUI from "./HomePlacesUI";
import placeList from "./PlaceList";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomePlace = () => {
  let settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <hr className="ma_tb" />
      <div className="container">
        <div className="col-lg-12 ma_tb">
          <h1 className="place_header">Explore Places</h1>
        </div>
        <Slider {...settings}>
          {placeList.map((items) => {
            return (
              <div className="col-lg-3">
                <HomePlacesUI
                  key={items.id}
                  placeImg={items.placeImg}
                  placeName={items.placeName}
                  placeLocation={items.placeLocation}
                  placeArea={items.placeArea}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default HomePlace;
