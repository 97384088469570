const vehiclesList = [
    {
        id: 1,
        vehicleName: "Buddha Airlines",
        vehicleImg: '/images/Box/Vehicles/buddha.jpg',
        vehiclePlace: "Pokhara", 
        vehiclePrice: "Rs 3000",
    },
    {
        id: 2,
        vehicleName: "Tourist Bus",
        vehicleImg: '/images/Box/Vehicles/shree.jpg',
        vehiclePlace: "Biratnagar",
        vehiclePrice: "Rs 2000",
    },
    {
        id: 3,
        vehicleName: "Shree Airlines",
        vehicleImg: '/images/Box/Vehicles/shree1.png',
        vehiclePlace: "Pokhara",
        vehiclePrice: "Rs 3000",
    },
    {
        id: 4,
        vehicleName: "Nepal Airlines",
        vehicleImg: '/images/Box/Vehicles/rnac1.jpg',
        vehiclePlace: "Biratnagar",
        vehiclePrice: "Rs 4000",
    },
    {
        id: 5,
        vehicleName: "Shree Airlines",
        vehicleImg: '/images/Box/Vehicles/shree.jpg',
        vehiclePlace: "Pokhara",
        vehiclePrice: "Rs 5500",
    },
    {
        id: 6,
        vehicleName: "Buddha Airlines",
        vehicleImg: '/images/Box/Vehicles/buddha.jpg',
        vehiclePlace: "Bhairahawa",
        vehiclePrice: "Rs 2500",
    },
    {
        id: 7,
        vehicleName: "Tourist Bus",
        vehicleImg: '/images/Box/Vehicles/bus2.jpg',
        vehiclePlace: "Janakpur",
        vehiclePrice: "Rs 1500",
    },
]

export default vehiclesList;