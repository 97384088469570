import React from 'react'

const Hotels = () => {
    return (
        <div>
            Hotels
        </div>
    )
}

export default Hotels
