import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import baseUrl from "../Constant/Api";
import unknown from "../../images/User/unknown.jpg";
// import Match from "../Match/Match";
import MatchUI from "../Match/MatchUI";
import "./UserPage.css";
import Logout from "./Logout";
import swal from "sweetalert";

const imageBasePath = "https://ghumfir003.pythonanywhere.com/";

const UserPage = (props) => {
  const [data1, setData1] = useState({});
  const [data2, setData2] = useState({});
  const [data3, setData3] = useState({});
  const [data1IsVerified, setData1IsVerified] = useState({});
  const [data2IsVerified, setData2IsVerified] = useState({});
  const [data3IsVerified, setData3IsVerified] = useState({});
  const [mobile1, setMobile1] = useState({});
  const [mobile2, setMobile2] = useState({});
  const [mobile3, setMobile3] = useState({});

  const [matchedUserInfo, setMatchedUser] = useState({});
  const [matchedSecUserInfo, setSecMatchedUser] = useState({});
  const [matchedThirdUserInfo, setThirdMatchedUser] = useState({});
  // const [matchedUserInfo, setMatchedUser] = useState({});
  const [matchedUserImg, setMatchedUserImg] = useState({});

  // const localImg = "https://ghumfir003.pythonanywhere.com/images/logo.png";

  const userData = JSON.parse(localStorage.getItem("data"));
  // const userName = userData.username;
  const FirstName = userData.first_name;
  const LastName = userData.last_name;
  const userEmail = userData.email;
  const userId = userData.pk;

  const token = localStorage.getItem("key");

  const getUsers = async () => {
    const response = await fetch(`${baseUrl}/match/${userId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      // body: JSON.stringify({})
    });

    const data = await response.json();
    console.log(data);

    const userData1 = data.match1_url;
    const userData2 = data.match2_url;
    const userData3 = data.match3_url;

    // console.log(userData);
    //
    // const token1 = localStorage.getItem("key");
    if (response.ok) {
      const responseData1 = await fetch(`${userData1}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      const responseParsedData1 = await responseData1.json();
      console.log("User1 ko data 1", responseParsedData1);

      // const ppImg = responseParsedData1.pp_info[0].pp_img;
      const isVerified = responseParsedData1.pp_info[0];

      console.log("Is this isverified", isVerified.pp_size);
      console.log(" OR Is this isverified", data1IsVerified.is_verified);
      // console.log(data1IsVerified.mobile);

      setData1IsVerified(isVerified);
      // console.log(ppImg);
      // setData1Img(ppImg , isVerified);

      // console.log(responseParsedData1);
      setData1(responseParsedData1);
      const responseData2 = await fetch(`${userData2}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      const responseParsedData2 = await responseData2.json();
      console.log(responseParsedData2);

      const is_verified = responseParsedData2.pp_info[0];

      console.log("User 2 ko image infor", is_verified);

      setData2IsVerified(is_verified);

      setData2(responseParsedData2);

      const responseData3 = await fetch(`${userData3}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      const responseParsedData3 = await responseData3.json();
      console.log(responseParsedData3);

      const is_verified3 = responseParsedData3.pp_info[0];

      console.log("User 3 ko image infor", is_verified3);

      setData3IsVerified(is_verified3);

      setData3(responseParsedData3);
    } else {
      swal(
        "Sorry!!!","No Companion Found!!! Please update your interests.","error"
      );
    }
  };

  const isInterested = async (event) => {
    const btnId = event.target.id;

    const response = await fetch(`${baseUrl}/match/${userId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    const responseData = await response.json();
    console.log(responseData);
    console.log(responseData.match1);
    let match1 = responseData.match1;
    let match2 = responseData.match2;
    let match3 = responseData.match3;

    if (btnId === "1") {
      const match1Request = await fetch(`${baseUrl}/match/${userId}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          preferred: match1,
        }),
      });

      const match1Success = await match1Request.json();
      console.log(match1Success);

      // console.log(`interest vaako user ${userId}`);
      // console.log(`ko user sanga interest vaako ho tyo user ${match1}`);
    } else if (btnId === "2") {
      const match2Request = await fetch(`${baseUrl}/match/${userId}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          preferred: match2,
        }),
      });

      const match2Success = await match2Request.json();
      console.log(match2Success);
      console.log("Hello");

      console.log(`interest vaako user ${userId}`);
      console.log(`ko user sanga interest vaako ho tyo user ${match2}`);
    } else if (btnId === "3") {
      const match3Request = await fetch(`${baseUrl}/match/${userId}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          preferred: match3,
        }),
      });

      const match3Success = await match3Request.json();
      console.log(match3Success);

      // console.log(`interest vaako user ${userId}`);
      // console.log(`ko user sanga interest vaako ho tyo user ${match3}`);
    }
  };

  const handleInterestedUser = async () => {
    const notify = await fetch(`${baseUrl}/matchNotif/${userId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    const notifyResponse = await notify.json();

    console.log(notifyResponse);
    console.log("Thello");
    console.log(notifyResponse[0].user);

    const interestedUserId = notifyResponse[0].user;
    console.log(interestedUserId);
    // const interestedUserImage = notifyResponse[0].pp_info;
    // console.log(interestedUserId);
    // console.log(interestedUserImage.is_verified);

    const interestedUsers = await fetch(
      `${baseUrl}/userInfo/${interestedUserId}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    const interestedResponse = await interestedUsers.json();
    console.log(interestedResponse);
    console.log("UserInfo bata Data pani aayo");
    console.log(interestedResponse.pp_info[0].mobile);
    const mobile1 = interestedResponse.pp_info[0];

    // console.log(interestedResponse.pp_info[0].is_verified);

    const imgUrl = "https://ghumfir003.pythonanywhere.com";
    const imgUrl2 = interestedResponse.pp_info[0].pp_size;

    // const finalImg = `${imgUrl}/${imgUrl2}`;
    // console.log("Final Image is",finalImg);

    console.log(interestedResponse.pp_info[0].pp_size);
    const finalImg = `${imgUrl}${imgUrl2}/`;

    console.log("Final Image is", finalImg);

    // const matchedFirstName = interestedResponse.first_name;
    // const matchedLastName = interestedResponse.last_name;
    // const matchedEmail = interestedResponse.email;

    // console.log(matchedUser);

    if (interestedUsers.status === 200) {
      setMatchedUserImg(finalImg);
      setMatchedUser(interestedResponse);
      setMobile1(mobile1);
    } else {
      alert("No user Found");
    }

    const secNotify = await fetch(`${baseUrl}/matchNotif/${userId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    const secNotifyResponse = await secNotify.json();

    console.log(secNotifyResponse);
    console.log("Second User interesrted vako");
    console.log(secNotifyResponse[1].user);

    const interestedSecUserId = secNotifyResponse[1].user;
    console.log(interestedSecUserId);
    // const interestedUserImage = notifyResponse[0].pp_info;
    // console.log(interestedUserId);
    // console.log(interestedUserImage.is_verified);

    const secInterestedUser = await fetch(
      `${baseUrl}/userInfo/${interestedSecUserId}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    const interestedSecResponse = await secInterestedUser.json();
    console.log(interestedSecResponse);
    console.log("UserInfo bata Data pani aayo");
    console.log(interestedSecResponse.username);
    console.log(interestedSecResponse.pp_info[0].mobile);

    const mobile2 = interestedSecResponse.pp_info[0];
    // console.log(interestedResponse.pp_info[0].is_verified);

    // const imgUrl = 'https://ghumfir003.pythonanywhere.com';
    // const imgUrl2 = interestedResponse.pp_info[0].pp_size;

    // const finalImg = `${imgUrl2}/${imgUrl}pp_size`;
    // console.log(finalImg);

    // console.log(interestedResponse.pp_info[0].pp_size);
    // const finalImg = `${imgUrl}${imgUrl2}/`;

    // console.log(finalImg);

    // const matchedFirstName = interestedResponse.first_name;
    // const matchedLastName = interestedResponse.last_name;
    // const matchedEmail = interestedResponse.email;

    // console.log(matchedUser);

    if (secInterestedUser.status === 200) {
      // setMatchedUserImg(finalImg);
      setSecMatchedUser(interestedSecResponse);
      setMobile2(mobile2);
    } else {
      alert("No user Found");
    }

    const thirdNotify = await fetch(`${baseUrl}/matchNotif/${userId}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    const thirdNotifyResponse = await thirdNotify.json();

    console.log(thirdNotifyResponse);
    console.log("Second User interesrted vako");
    console.log(thirdNotifyResponse[2].user);

    const interestedThirdUserId = thirdNotifyResponse[2].user;
    console.log(interestedThirdUserId);
    // const interestedUserImage = notifyResponse[0].pp_info;
    // console.log(interestedUserId);
    // console.log(interestedUserImage.is_verified);

    const thirdInterestedUser = await fetch(
      `${baseUrl}/userInfo/${interestedThirdUserId}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    );
    const interestedThirdResponse = await thirdInterestedUser.json();
    console.log(interestedThirdResponse);
    console.log("UserInfo bata Data pani aayo");
    console.log(interestedThirdResponse.username);

    const mobile3 = interestedThirdResponse.pp_info[0];
    // console.log(interestedResponse.pp_info[0].is_verified);

    // const imgUrl = 'https://ghumfir003.pythonanywhere.com';
    // const imgUrl2 = interestedResponse.pp_info[0].pp_size;

    // const finalImg = `${imgUrl2}/${imgUrl}pp_size`;
    // console.log(finalImg);

    // console.log(interestedResponse.pp_info[0].pp_size);
    // const finalImg = `${imgUrl}${imgUrl2}/`;

    // console.log(finalImg);

    // const matchedFirstName = interestedResponse.first_name;
    // const matchedLastName = interestedResponse.last_name;
    // const matchedEmail = interestedResponse.email;

    // console.log(matchedUser);

    if (thirdInterestedUser.status === 200) {
      // setMatchedUserImg(finalImg);
      setThirdMatchedUser(interestedThirdResponse);
      setMobile3(mobile3);
    } else {
      alert("No user Found");
    }

    // console.log(dat);
  };

  return (
    <div>
      <hr />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h3 className="user_profile_name">Welcome {FirstName} </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-4 user_img_div ma_tb">
                <img className="user_img" src={unknown} alt="sjdfh" />
              </div>
              <div className="col-lg-4">
                <div className="userData ">
                  <h6 className="userName">
                    <span>
                      <i class="fas fa-user-secret"></i>
                    </span>{" "}
                    {FirstName} {LastName}
                  </h6>
                  <h6 className="user_email">
                    <span>
                      <i class="far fa-envelope"></i>
                    </span>{" "}
                    {userEmail}
                  </h6>
                  {/* <p className="user_id "> <span><i class="fas fa-key"></i></span> {userId}</p> */}
                </div>
              </div>
              <div className="col-lg-4">
                <div className="interest_div">
                  <NavLink to="/interest" className="btn btn-outline-success">
                    Submit Your Interst
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-4">
                <NavLink to="/kyc" className="btn btn-outline-success">
                  Update Kyc
                </NavLink>
              </div>
              <div className="col-lg-4">
                <button
                  className="btn btn-outline-success"
                  onClick={handleInterestedUser}
                >
                  Show Interested
                </button>
                {/* <div className="matchuser_img_div ma_tb">

            <img src={matchedUserImg} className = "matchuser_img" alt="useImg" />
            
            </div> */}

                <p className="interested_user ma_tb">
                  {matchedUserInfo.first_name} {matchedUserInfo.last_name}
                </p>
                <p className="interested_user ma_tb">{matchedUserInfo.email}</p>
                <p className="interested_user ma_tb">{mobile1.mobile}</p>
                {/* <p className="interested_user ma_tb">
                {data1IsVerified.mobile}
                </p> */}

                <hr />

                {/* Second User  */}

                <p className="interested_user ma_tb">
                  {matchedSecUserInfo.first_name} {matchedSecUserInfo.last_name}
                </p>
                <p className="interested_user ma_tb">
                  {matchedSecUserInfo.email}
                </p>

                <p className="interested_user ma_tb">{mobile2.mobile}</p>
                <hr />
                {/* Third User  */}

                <p className="interested_user ma_tb">
                  {matchedThirdUserInfo.first_name}{" "}
                  {matchedThirdUserInfo.last_name}
                </p>
                <p className="interested_user ma_tb">
                  {matchedThirdUserInfo.email}
                </p>

                <p className="interested_user ma_tb">{mobile3.mobile}</p>

                <hr />
              </div>
              <div className="col-lg-4">
                {/* <NavLink to="/login" className="btn btn-outline-success">
                  <Logout />
                </NavLink> */}
                <Logout />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <button className="btn btn-ouline-dark ma_tb " onClick={getUsers}>
            Show Recommended Companions
          </button>

          {data1IsVerified.pp_size ? (
            <>
              <div className="col-lg-4 tc shadow   rounded">
                <MatchUI
                  userImg={`${imageBasePath}${data1IsVerified.pp_size}`}
                  userName={data1.first_name}
                  userEmail={data1.email}
                  userId={data1.id}
                  userVerified={data1IsVerified.is_verified}
                />
                <button
                  className="btn btn-outline-dark ma_tb"
                  id="1"
                  onClick={isInterested}
                >
                  Interested
                </button>
              </div>
            </>
          ) : (
            <></>
          )}

          {data2IsVerified.pp_size ? (
            <>
              <div className="col-lg-4 tc shadow  rounded ">
                <MatchUI
                  userImg={`${imageBasePath}${data2IsVerified.pp_size}`}
                  userName={data2.first_name}
                  userEmail={data2.email}
                  userVerified={data2IsVerified.is_verified}
                  userId={data2.id}
                />
                <button
                  className="btn btn-outline-dark ma_tb"
                  id="2"
                  onClick={isInterested}
                >
                  Interested
                </button>
              </div>
            </>
          ) : (
            <></>
          )}

          {data3IsVerified.pp_size ? (
            <>
              <div className="col-lg-4 tc shadow   rounded ">
                <MatchUI
                  userImg={`${imageBasePath}${data3IsVerified.pp_size}`}
                  userName={data3.first_name}
                  userEmail={data3.email}
                  userId={data3.id}
                  userVerified={data3IsVerified.is_verified}
                />
                <button
                  className="btn btn-outline-dark ma_tb"
                  id="3"
                  onClick={isInterested}
                >
                  Interested
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserPage;
