import React from "react";
import HotelUI from "./HotelUI";
import hotelsList from "./HotelList";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Hotels = () => {
  let settings = {
    dots: true,
    arrows: true,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <hr className="ma_tb"/>
      <div className="container">
        <div className="col-lg-12 ma_tb">
          <h1 className="hotel_header">Hotels</h1>
        </div>
        <Slider {...settings}>
          {hotelsList.map((items) => {
            return (
              <div className="col-lg-3">
                <HotelUI
                  key={items.id}
                  hotelsImg={items.hotelImg}
                  hotelsPlace = {items.hotelPlace}
                  hotelsName={items.hotelName}
                  hotelsPrice={items.hotelPrice}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Hotels;
