import React from "react";
import VehiclesUI from "./VehiclesUI";
import vehiclesList from "./VehicleList";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Vehicles = () => {
  let settings = {
    dots: true,
    arrows: true,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <hr className="ma_tb" />
      <div className="container">
        <div className="col-lg-12 ma_tb">
          <h1 className="vehicle_header">Vehicles</h1>
        </div>
        <Slider {...settings}>
          {vehiclesList.map((items) => {
            return (
              <div className="col-lg-3">
                <VehiclesUI
                  key={items.id}
                  vehicleImg={items.vehicleImg}
                  vehiclePlace = {items.vehiclePlace}
                  vehicleName={items.vehicleName}
                  vehiclePrice={items.vehiclePrice}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Vehicles;
