import React, { useState } from "react";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import baseUrl from "../Constant/Api";
import './Interest.css';
const Interest = () => {
  const userDetails = JSON.parse(localStorage.getItem("data"));
  const userId = userDetails.pk;

  const history = useHistory();
  const [userData, setUserData] = useState({
    user: `${userId}`,
    location: "",
  });
  const [checkbox, setCheckBox] = useState({
    smoking: false,
    drinking: false,
    party: false,
    driving: false,
    musical: false,
  });

  const handleInputs = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setUserData({ ...userData, [name]: value });
  };

  const handleCheckBox = (event) => {
    let name = event.target.name;
    let checked = event.target.checked;

    setCheckBox({ ...checkbox, [name]: checked });
  };

  const handlePostData = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("key");
    const { smoking, drinking, party, driving, musical } = checkbox;
    const { user ,location } = userData;
    const responseData = await fetch(`${baseUrl}/interest/`, {
      method: "POST",
      body: JSON.stringify({
        user,
        smoking,
        drinking,
        party,
        driving,
        musical,
        location,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    const response = await responseData.json();
    console.log(response);
    history.push("/success");

    const responseData1 = await fetch(`${baseUrl}/interest/${userId}/`, {
      method: "PATCH",
      body: JSON.stringify({
        smoking,
        drinking,
        party,
        driving,
        musical,
        location,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });

    const response1 = await responseData1.json();
    console.log(response1);
  };

  return (
    <div>
      <div className="container interest_container">
        <h1 className="tc interest_title">Select Your Interest</h1>
        <form className="form-check">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 ma_tb interest_box">
                
                <select
                  className="form-control interest_location ma_b"
                  name="location"
                  onChange={handleInputs}
                  value={userData.location}
                > 
                  <option  value="ACT">Annapurna Cirtuit Trek</option>
                  <option  value="PKR">Pokhara</option>
                  <option  value="MHT">Mardi Himal Trek</option>
                  <option  value="SRHA">Sauraha</option>
                </select>
                
                <input
                  className="form-control interest_location ma_b "
                  type="text"
                  name="user"
                  value={userData.user}
                  onChange={handleCheckBox}
                  disabled
                  hidden
                />
                <input
                  className="form-check-input "
                  type="checkbox"
                  name="smoking"
                  onChange={handleCheckBox}
                  defaultChecked={checkbox.smoking}
                />
                <p className="interest_data">Smoking </p> <br />
                <input
                  className="form-check-input "
                  type="checkbox"
                  name="drinking"
                  onChange={handleCheckBox}
                  defaultChecked={checkbox.drinking}
                />
                <p className="interest_data">Drinking</p> <br />
                <input
                  className="form-check-input "
                  type="checkbox"
                  name="party"
                  onChange={handleCheckBox}
                  defaultChecked={checkbox.party}
                />
                <p className="interest_data">Party</p> <br />
                <input
                  className="form-check-input "
                  type="checkbox"
                  name="driving"
                  onChange={handleCheckBox}
                  defaultChecked={checkbox.driving}
                />
                <p className="interest_data">Driving</p> <br />
                <input
                  className="form-check-input "
                  type="checkbox"
                  name="musical"
                  onChange={handleCheckBox}
                  defaultChecked={checkbox.smoking}
                />
                <p className="interest_data">Musical</p> <br />
                <button
                  className="btn btn-outline-success"
                  type="submit"
                  onClick={handlePostData}
                >
                  Submit <span><i class="fas fa-check-circle"></i></span>
                </button>
                <NavLink to="/success">
                  
                  <button
                    className="btn btn-outline-dark m-2"
                    onClick={handlePostData}
                  >
                    Back to profile <span><i class="fas fa-user-alt"></i></span>
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Interest;
