import React from 'react'
import { useHistory } from 'react-router-dom'
import baseUrl from '../Constant/Api'

const Logout = () => {
    const history = useHistory();
    const logout = async() => {
         const userLogout = await fetch(`${baseUrl}/auth/logout/`, {
             method: "POST"
         });

         const response =await userLogout.json();
            if(userLogout.status === 200){

                console.log("Logout Resposne",response);
                localStorage.removeItem('key');
                history.push('/login');
            } else {
                console.log("Unable to logout");
            }
    }

    return (
        <div>
            <button onClick={logout} className="btn-outline-success">Logout</button>
        </div>
    )
}

export default Logout
